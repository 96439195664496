import { GET, POSTBody,POST } from '../apiFn';
import url from '../url';
import APIType from './type';
 
 
export default {
  getBmsDataPage: (params:APIType.getBMSListParam): Promise<APIType.getBMSListRes> => GET(url.getBMSList, params),
  getBmsDataByID: (params: APIType.getByIdBMSParam): Promise<APIType.getBMSRes> => GET(url.getBMSById, params),
  
  

}; 
